import React from 'react';
import styled from 'styled-components/macro';

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell as MUITableCell,
  Checkbox,
  Tooltip,
  TableSortLabel,
  withStyles,
  Button,
  CircularProgress,
} from '@material-ui/core';
import _ from 'lodash';
import {fetchParticipants, fetchSubmissions} from '../lib/Firebase';
import XLSX, {WorkBook} from 'xlsx';

import moment, * as _moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';

import {ParticipantRowSource, Participant, TaskTrial} from '../models';

moment.tz.setDefault('Australia/Melbourne');
momentDurationFormatSetup(_moment);

const HEADERS = [
  'PARTICIPANT ID',
  'REGISTRATION DATE',
  'POST-TEST SURVEY DUE',
  // 'WEEK 8 QUESTIONS EXPECTED DATE',
  // 'WEEK 8 QUESTIONS COMPLETE?',
  // 'WEEK 16 QUESTIONS EXPECTED',
  // 'WEEK 16 QUESTIONS COMPLETE?',
];

const NUM_AAT_TRIALS = 40;

type ParticipantRowDisplay = {
  sourceDataIndex: number;
  participantId: string;
  registrationDate: string;
  postTestSurveyDue: string;
  followUpQuestionsDue: string;
  followUpWeek16QuestionsDue: string;
  followUpComplete: string;
  followUpWeek16Complete: string;
  isSelected: boolean;
  registrationDateSortValue: number;
  postTestSurveyDueSortValue: number;
  followUpQuestionsDueSortValue: number;
  followUpWeek16QuestionsDueSortValue: number;
  followUpCompleteSortValue: number;
  followUpWeek16CompleteSortValue: number;
  numPersonalAlcoholImages: number;
  numPersonalPositiveImages: number;
};

const getSortValueForColumnIndex = (
  e: ParticipantRowDisplay,
  columnIndex: number,
) =>
  columnIndex === 0
    ? e.participantId
    : columnIndex === 1
    ? e.registrationDateSortValue
    : columnIndex === 2
    ? e.postTestSurveyDueSortValue
    : columnIndex === 3
    ? e.followUpQuestionsDueSortValue
    : columnIndex === 4
    ? e.followUpCompleteSortValue
    : columnIndex === 5
    ? e.followUpWeek16QuestionsDueSortValue
    : columnIndex === 6
    ? e.followUpWeek16CompleteSortValue
    : '?';

interface Props {}

interface State {
  rawData: ParticipantRowSource[];
  data: ParticipantRowDisplay[]; // contains data that has been queried / filtered on for display purposes
  loading: boolean;
  selectedRowsNo: number;
  columnSortDirection: {[columnIndex: number]: 'asc' | 'desc' | undefined};
}

class DataTable extends React.Component<Props, State> {
  state: State = {
    rawData: [],
    data: [],
    loading: true,
    selectedRowsNo: 0,
    columnSortDirection: {
      0: 'asc',
      1: undefined,
      2: undefined,
      3: undefined,
      4: undefined,
    },
  };

  async componentWillMount() {
    const participants = await fetchParticipants();
    const sessions = await fetchSubmissions();
    const rawData: ParticipantRowSource[] = Object.values(sessions);
    this.setState({
      rawData,
      data: rawData.map((row, i) => {
        const participant: Participant | null =
          participants[row.participant_id];
        const registrationDate =
          participant?.registrationDate &&
          moment(participant.registrationDate).format('DD/MM/YYYY');
        const postTestSurveyDue =
          participant?.registrationDate &&
          moment(participant.registrationDate)
            .add(28, 'days')
            .format('DD/MM/YYYY');
        const followUpQuestionsDue =
          participant?.registrationDate &&
          moment(participant.registrationDate)
            .add(56, 'days')
            .format('DD/MM/YYYY');
        const followUpWeek16QuestionsDue =
          participant?.registrationDate &&
          moment(participant.registrationDate)
            .add(112, 'days')
            .format('DD/MM/YYYY');

        // Defined as: when follow up questions has been done.
        const followUpComplete = !registrationDate
          ? 'N/A'
          : row.week8_consumption_days && row.week8_consumption_drinks
          ? 'Yes'
          : 'No';

        // Defined as: when week16 follow up questions has been done.
        const followUpWeek16Complete = !registrationDate
          ? 'N/A'
          : row.week16_consumption_days && row.week16_consumption_drinks
          ? 'Yes'
          : 'No';

        const numPersonalAlcoholImages = participant?.numPersonalAlcoholImages;
        const numPersonalPositiveImages =
          participant?.numPersonalPositiveImages;

        return {
          sourceDataIndex: i,
          participantId: row.participant_id,
          registrationDate: registrationDate || 'N/A',
          registrationDateSortValue:
            participant?.registrationDate?.valueOf() || Number.MIN_SAFE_INTEGER,
          postTestSurveyDue: postTestSurveyDue || 'N/A',
          postTestSurveyDueSortValue:
            participant?.registrationDate?.valueOf() || Number.MIN_SAFE_INTEGER,
          followUpQuestionsDue: followUpQuestionsDue || 'N/A',
          followUpQuestionsDueSortValue:
            participant?.registrationDate?.valueOf() || Number.MIN_SAFE_INTEGER,
          followUpComplete,
          followUpCompleteSortValue:
            followUpComplete === 'Yes' ? 1 : followUpComplete === 'No' ? 0 : -1,
          followUpWeek16QuestionsDue: followUpWeek16QuestionsDue || 'N/A',
          followUpWeek16QuestionsDueSortValue:
            participant?.registrationDate?.valueOf() || Number.MIN_SAFE_INTEGER,
          followUpWeek16Complete,
          followUpWeek16CompleteSortValue:
            followUpComplete === 'Yes'
              ? 1
              : followUpWeek16Complete === 'No'
              ? 0
              : -1,
          isSelected: false,
          numPersonalAlcoholImages,
          numPersonalPositiveImages,
        };
      }),
      loading: false,
    });
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    let shouldUpdate = false;
    if (
      JSON.stringify(this.state.columnSortDirection) !==
      JSON.stringify(nextState.columnSortDirection)
    ) {
      shouldUpdate = true;
    }
    if (this.state.data.length !== nextState.data.length) {
      shouldUpdate = true;
    }
    if (this.state.selectedRowsNo !== nextState.selectedRowsNo) {
      shouldUpdate = true;
    }
    return shouldUpdate;
  }

  onCheck(index: number, checked: boolean) {
    const selectedRowsNo = this.state.selectedRowsNo + (checked ? 1 : -1);

    this.setState({
      data: Object.assign([], this.state.data, {
        [index]: {
          ...this.state.data[index],
          isSelected: checked,
        },
      }),
      selectedRowsNo: selectedRowsNo,
    });
  }

  onCheckAll() {
    this.setState({
      data: this.state.data.map((e) => ({
        ...e,
        isSelected: this.state.selectedRowsNo === 0,
      })),
      selectedRowsNo:
        this.state.selectedRowsNo === 0 ? this.state.data.length : 0,
    });
  }

  downloadMultiple() {
    const shouldDownloadAll = this.state.selectedRowsNo === 0;

    const wb = XLSX.utils.book_new();
    _.forEach(this.state.data, (value) => {
      if (shouldDownloadAll || value.isSelected) {
        this.download(value, wb);
      }
    });

    XLSX.writeFile(wb, 'export.xlsx');
  }

  download(row: ParticipantRowDisplay, oldWb?: WorkBook) {
    const wb = oldWb ?? XLSX.utils.book_new();

    const rowSource = this.state.rawData[row.sourceDataIndex];

    const abmVasJson = (rowSource.task_sessions || [])
      .filter((e) => e.variant === 'ABM')
      .map((e) => {
        const date = moment(e.start_time).format('YYYY-MM-DD');
        const time = moment(e.start_time).format('HH:mm:ss');

        return {
          'Participant ID': rowSource.participant_id,
          Platform: e.platform,
          'App Version': e.app_version,
          'Start Date': date,
          'Start Time': time,
          'Pre-ABM VAS craving measure': e.session_vas?.pre_task,
          'Post-ABM VAS craving measure': e.session_vas?.post_task,
        };
      });

    const shamVasJson = (rowSource.task_sessions || [])
      .filter((e) => e.variant === 'SHAM')
      .map((e) => {
        const date = moment(e.start_time).format('YYYY-MM-DD');
        const time = moment(e.start_time).format('HH:mm:ss');

        return {
          'Participant ID': rowSource.participant_id,
          Platform: e.platform,
          'App Version': e.app_version,
          'Start Date': date,
          'Start Time': time,
          'Pre-SHAM VAS craving measure': e.session_vas?.pre_task,
          'Post-SHAM VAS craving measure': e.session_vas?.post_task,
        };
      });

    let abmVasSheet = oldWb?.Sheets['ABM (Training) VAS'];

    let shamVasSheet = oldWb?.Sheets['SHAM (Training) VAS'];

    if (abmVasJson.length) {
      if (abmVasSheet == null) {
        abmVasSheet = XLSX.utils.json_to_sheet(abmVasJson);
        XLSX.utils.book_append_sheet(wb, abmVasSheet, 'ABM (Training) VAS');
      } else {
        // Fix SheetJS: https://github.com/SheetJS/sheetjs/issues/1364#issuecomment-573403688
        let json = XLSX.utils.sheet_to_json(abmVasSheet!);
        json = json.concat(abmVasJson);

        XLSX.utils.sheet_add_json(abmVasSheet, json, {
          skipHeader: false,
          origin: 0,
        });
      }
    }

    if (shamVasJson.length) {
      if (shamVasSheet == null) {
        shamVasSheet = XLSX.utils.json_to_sheet(shamVasJson);
        XLSX.utils.book_append_sheet(wb, shamVasSheet, 'SHAM (Training) VAS');
      } else {
        let json = XLSX.utils.sheet_to_json(shamVasSheet!);
        json = json.concat(shamVasJson);

        XLSX.utils.sheet_add_json(shamVasSheet, json, {
          skipHeader: false,
          origin: 0,
        });
      }
    }

    const aatVasJson = (rowSource.task_sessions || [])
      .filter((e) => e.variant === 'AAT')
      .map((e) => {
        const date = moment(e.start_time).format('YYYY-MM-DD');
        const time = moment(e.start_time).format('HH:mm:ss');

        return {
          'Participant ID': rowSource.participant_id,
          Platform: e.platform,
          'App Version': e.app_version,
          'Start Date': date,
          'Start Time': time,
          'Pre-AAT VAS craving measure': e.session_vas?.pre_task,
          'Post-AAT VAS craving measure': e.session_vas?.post_task,
        };
      });

    let aatVasSheet = oldWb?.Sheets['AAT VAS'];
    if (aatVasSheet == null) {
      aatVasSheet = XLSX.utils.json_to_sheet(aatVasJson);
      XLSX.utils.book_append_sheet(wb, aatVasSheet, 'AAT VAS');
    } else {
      // Fix SheetJS: https://github.com/SheetJS/sheetjs/issues/1364#issuecomment-573403688
      let json = XLSX.utils.sheet_to_json(aatVasSheet!);
      json = json.concat(aatVasJson);

      XLSX.utils.sheet_add_json(aatVasSheet, json, {
        skipHeader: false,
        origin: 0,
      });
    }

    const taskSessions = (rowSource.task_sessions || []).filter(
      (e) => e.participant_id !== undefined,
    );

    const abmTaskJson = taskSessions
      .filter((e) => e.variant === 'ABM')
      .map((e) => {
        const date = moment(e.start_time).format('YYYY-MM-DD');
        const time = moment(e.start_time).format('HH:mm:ss');

        let start = moment(e.start_time);
        let end = moment(e.end_time);

        var duration = moment.duration(end.diff(start));
        let durationString = moment
          .duration(duration.asSeconds(), 'seconds')
          .format();

        return {
          'Participant ID': rowSource.participant_id,
          'App Version': e.app_version,
          'Start Date': date,
          'Start Time': time,
          Duration: durationString,
          Platform: e.platform,
          Score: e.score,
          'Is Complete?': _.capitalize(e.completed.toString()),
          'Average Congruent Alcohol Trial Reaction Time': e.trials
            ? (() => {
                const congruentAlcoholTrials = e.trials.filter(
                  (trial) =>
                    trial.imageOrientation === 'Portrait' &&
                    trial.imageType === 'Alcohol',
                );
                return congruentAlcoholTrials.length > 0
                  ? congruentAlcoholTrials.reduce(
                      (sum, trial) => sum + trial.reactionTime!, // TODO [@Jason]: verify this assumption (believe it's a typing issue due to shared type being used for BEFORE the user response, and after)
                      0,
                    ) / congruentAlcoholTrials.length
                  : 'N/A';
              })()
            : 'N/A',
          'Average Congruent Alcohol Trial Error Rate': e.trials
            ? (() => {
                const congruentAlcoholTrials = e.trials.filter(
                  (trial) =>
                    trial.imageOrientation === 'Portrait' &&
                    trial.imageType === 'Alcohol',
                );
                return congruentAlcoholTrials.length > 0
                  ? 100 *
                      (congruentAlcoholTrials.filter(
                        (trial) => trial.userResponse! === true, // TODO [@Jason]: verify this assumption (believe it's a typing issue due to shared type being used for BEFORE the user response, and after)
                      ).length /
                        congruentAlcoholTrials.length)
                  : 'N/A';
              })()
            : 'N/A',
          'Average Incongruent Alcohol Trial Reaction Time': e.trials
            ? (() => {
                const incongruentAlcoholTrials = e.trials.filter(
                  (trial) =>
                    trial.imageOrientation === 'Landscape' &&
                    trial.imageType === 'Alcohol',
                );
                return incongruentAlcoholTrials.length > 0
                  ? incongruentAlcoholTrials.reduce(
                      (sum, trial) => sum + trial.reactionTime!, // TODO [@Jason]: verify this assumption (believe it's a typing issue due to shared type being used for BEFORE the user response, and after)
                      0,
                    ) / incongruentAlcoholTrials.length
                  : 'N/A';
              })()
            : 'N/A',
          'Average Incongruent Alcohol Trial Error Rate': e.trials
            ? (() => {
                const incongruentAlcoholTrials = e.trials.filter(
                  (trial) =>
                    trial.imageOrientation === 'Landscape' &&
                    trial.imageType === 'Alcohol',
                );
                return incongruentAlcoholTrials.length > 0
                  ? 100 *
                      (incongruentAlcoholTrials.filter(
                        (trial) => trial.userResponse! === true, // TODO [@Jason]: verify this assumption (believe it's a typing issue due to shared type being used for BEFORE the user response, and after)
                      ).length /
                        incongruentAlcoholTrials.length)
                  : 'N/A';
              })()
            : 'N/A',
          'Average Congruent Non-alcohol Trial Reaction Time': e.trials
            ? (() => {
                const congruentNonAlcoholTrials = e.trials.filter(
                  (trial) =>
                    trial.imageOrientation === 'Landscape' &&
                    trial.imageType === 'Neutral',
                );
                return congruentNonAlcoholTrials.length > 0
                  ? congruentNonAlcoholTrials.reduce(
                      (sum, trial) => sum + trial.reactionTime!, // TODO [@Jason]: verify this assumption (believe it's a typing issue due to shared type being used for BEFORE the user response, and after)
                      0,
                    ) / congruentNonAlcoholTrials.length
                  : 'N/A';
              })()
            : 'N/A',
          'Average Congruent Non-alcohol Trial Error Rate': e.trials
            ? (() => {
                const congruentNonAlcoholTrials = e.trials.filter(
                  (trial) =>
                    trial.imageOrientation === 'Landscape' &&
                    trial.imageType === 'Neutral',
                );
                return congruentNonAlcoholTrials.length > 0
                  ? 100 *
                      (congruentNonAlcoholTrials.filter(
                        (trial) => trial.userResponse! === true, // TODO [@Jason]: verify this assumption (believe it's a typing issue due to shared type being used for BEFORE the user response, and after)
                      ).length /
                        congruentNonAlcoholTrials.length)
                  : 'N/A';
              })()
            : 'N/A',
          'Average Incongruent Non-alcohol Trial Reaction Time': e.trials
            ? (() => {
                const incongruentNonAlcoholTrials = e.trials.filter(
                  (trial) =>
                    trial.imageOrientation === 'Portrait' &&
                    trial.imageType === 'Neutral',
                );
                return incongruentNonAlcoholTrials.length > 0
                  ? incongruentNonAlcoholTrials.reduce(
                      (sum, trial) => sum + trial.reactionTime!, // TODO [@Jason]: verify this assumption (believe it's a typing issue due to shared type being used for BEFORE the user response, and after)
                      0,
                    ) / incongruentNonAlcoholTrials.length
                  : 'N/A';
              })()
            : 'N/A',
          'Average Incongruent Non-alcohol Trial Error Rate': e.trials
            ? (() => {
                const incongruentNonAlcoholTrials = e.trials.filter(
                  (trial) =>
                    trial.imageOrientation === 'Portrait' &&
                    trial.imageType === 'Neutral',
                );
                return incongruentNonAlcoholTrials.length > 0
                  ? 100 *
                      (incongruentNonAlcoholTrials.filter(
                        (trial) => trial.userResponse! === true, // TODO [@Jason]: verify this assumption (believe it's a typing issue due to shared type being used for BEFORE the user response, and after)
                      ).length /
                        incongruentNonAlcoholTrials.length)
                  : 'N/A';
              })()
            : 'N/A',
        };
      });

    let abmTaskSheet = oldWb?.Sheets['ABM Task'];
    if (abmVasJson.length) {
      if (abmTaskSheet == null) {
        abmTaskSheet = XLSX.utils.json_to_sheet(abmTaskJson);
        XLSX.utils.book_append_sheet(wb, abmTaskSheet, 'ABM Task');
      } else {
        let json = XLSX.utils.sheet_to_json(abmTaskSheet!);
        json = json.concat(abmTaskJson);

        XLSX.utils.sheet_add_json(abmTaskSheet!, json, {
          skipHeader: false,
          origin: 0,
        });
      }
    }

    const shamTaskJson = taskSessions
      .filter((e) => e.variant === 'SHAM')
      .map((e) => {
        const date = moment(e.start_time).format('YYYY-MM-DD');
        const time = moment(e.start_time).format('HH:mm:ss');

        let start = moment(e.start_time);
        let end = moment(e.end_time);

        var duration = moment.duration(end.diff(start));
        let durationString = moment
          .duration(duration.asSeconds(), 'seconds')
          .format();

        return {
          'Participant ID': rowSource.participant_id,
          'App Version': e.app_version,
          'Start Date': date,
          'Start Time': time,
          Duration: durationString,
          Platform: e.platform,
          Score: e.score,
          'Is Complete?': _.capitalize(e.completed.toString()),
          'Average Congruent Alcohol Trial Reaction Time': e.trials
            ? (() => {
                const congruentAlcoholTrials = e.trials.filter(
                  (trial) =>
                    trial.imageOrientation === 'Portrait' &&
                    trial.imageType === 'Alcohol',
                );
                return congruentAlcoholTrials.length > 0
                  ? congruentAlcoholTrials.reduce(
                      (sum, trial) => sum + trial.reactionTime!, // TODO [@Jason]: verify this assumption (believe it's a typing issue due to shared type being used for BEFORE the user response, and after)
                      0,
                    ) / congruentAlcoholTrials.length
                  : 'N/A';
              })()
            : 'N/A',
          'Average Congruent Alcohol Trial Error Rate': e.trials
            ? (() => {
                const congruentAlcoholTrials = e.trials.filter(
                  (trial) =>
                    trial.imageOrientation === 'Portrait' &&
                    trial.imageType === 'Alcohol',
                );
                return congruentAlcoholTrials.length > 0
                  ? 100 *
                      (congruentAlcoholTrials.filter(
                        (trial) => trial.userResponse! === true, // TODO [@Jason]: verify this assumption (believe it's a typing issue due to shared type being used for BEFORE the user response, and after)
                      ).length /
                        congruentAlcoholTrials.length)
                  : 'N/A';
              })()
            : 'N/A',
          'Average Incongruent Alcohol Trial Reaction Time': e.trials
            ? (() => {
                const incongruentAlcoholTrials = e.trials.filter(
                  (trial) =>
                    trial.imageOrientation === 'Landscape' &&
                    trial.imageType === 'Alcohol',
                );
                return incongruentAlcoholTrials.length > 0
                  ? incongruentAlcoholTrials.reduce(
                      (sum, trial) => sum + trial.reactionTime!, // TODO [@Jason]: verify this assumption (believe it's a typing issue due to shared type being used for BEFORE the user response, and after)
                      0,
                    ) / incongruentAlcoholTrials.length
                  : 'N/A';
              })()
            : 'N/A',
          'Average Incongruent Alcohol Trial Error Rate': e.trials
            ? (() => {
                const incongruentAlcoholTrials = e.trials.filter(
                  (trial) =>
                    trial.imageOrientation === 'Landscape' &&
                    trial.imageType === 'Alcohol',
                );
                return incongruentAlcoholTrials.length > 0
                  ? 100 *
                      (incongruentAlcoholTrials.filter(
                        (trial) => trial.userResponse! === true, // TODO [@Jason]: verify this assumption (believe it's a typing issue due to shared type being used for BEFORE the user response, and after)
                      ).length /
                        incongruentAlcoholTrials.length)
                  : 'N/A';
              })()
            : 'N/A',
          'Average Congruent Non-alcohol Trial Reaction Time': e.trials
            ? (() => {
                const congruentNonAlcoholTrials = e.trials.filter(
                  (trial) =>
                    trial.imageOrientation === 'Landscape' &&
                    trial.imageType === 'Neutral',
                );
                return congruentNonAlcoholTrials.length > 0
                  ? congruentNonAlcoholTrials.reduce(
                      (sum, trial) => sum + trial.reactionTime!, // TODO [@Jason]: verify this assumption (believe it's a typing issue due to shared type being used for BEFORE the user response, and after)
                      0,
                    ) / congruentNonAlcoholTrials.length
                  : 'N/A';
              })()
            : 'N/A',
          'Average Congruent Non-alcohol Trial Error Rate': e.trials
            ? (() => {
                const congruentNonAlcoholTrials = e.trials.filter(
                  (trial) =>
                    trial.imageOrientation === 'Landscape' &&
                    trial.imageType === 'Neutral',
                );
                return congruentNonAlcoholTrials.length > 0
                  ? 100 *
                      (congruentNonAlcoholTrials.filter(
                        (trial) => trial.userResponse! === true, // TODO [@Jason]: verify this assumption (believe it's a typing issue due to shared type being used for BEFORE the user response, and after)
                      ).length /
                        congruentNonAlcoholTrials.length)
                  : 'N/A';
              })()
            : 'N/A',
          'Average Incongruent Non-alcohol Trial Reaction Time': e.trials
            ? (() => {
                const incongruentNonAlcoholTrials = e.trials.filter(
                  (trial) =>
                    trial.imageOrientation === 'Portrait' &&
                    trial.imageType === 'Neutral',
                );
                return incongruentNonAlcoholTrials.length > 0
                  ? incongruentNonAlcoholTrials.reduce(
                      (sum, trial) => sum + trial.reactionTime!, // TODO [@Jason]: verify this assumption (believe it's a typing issue due to shared type being used for BEFORE the user response, and after)
                      0,
                    ) / incongruentNonAlcoholTrials.length
                  : 'N/A';
              })()
            : 'N/A',
          'Average Incongruent Non-alcohol Trial Error Rate': e.trials
            ? (() => {
                const incongruentNonAlcoholTrials = e.trials.filter(
                  (trial) =>
                    trial.imageOrientation === 'Portrait' &&
                    trial.imageType === 'Neutral',
                );
                return incongruentNonAlcoholTrials.length > 0
                  ? 100 *
                      (incongruentNonAlcoholTrials.filter(
                        (trial) => trial.userResponse! === true, // TODO [@Jason]: verify this assumption (believe it's a typing issue due to shared type being used for BEFORE the user response, and after)
                      ).length /
                        incongruentNonAlcoholTrials.length)
                  : 'N/A';
              })()
            : 'N/A',
        };
      });

    let shamTaskSheet = oldWb?.Sheets['SHAM Task'];
    if (shamVasJson.length) {
      if (shamTaskSheet == null) {
        shamTaskSheet = XLSX.utils.json_to_sheet(shamTaskJson);
        XLSX.utils.book_append_sheet(wb, shamTaskSheet, 'SHAM Task');
      } else {
        let json = XLSX.utils.sheet_to_json(shamTaskSheet!);
        json = json.concat(shamTaskJson);

        XLSX.utils.sheet_add_json(shamTaskSheet!, json, {
          skipHeader: false,
          origin: 0,
        });
      }
    }

    const aatTaskJson = taskSessions
      .filter((e) => e.variant === 'AAT')
      .map((e) => {
        const date = moment(e.start_time).format('YYYY-MM-DD');
        const time = moment(e.start_time).format('HH:mm:ss');

        let start = moment(e.start_time);
        let end = moment(e.end_time);

        var duration = moment.duration(end.diff(start));
        let durationString = moment
          .duration(duration.asSeconds(), 'seconds')
          .format();

        return {
          'Participant ID': rowSource.participant_id,
          'App Version': e.app_version,
          'Start Date': date,
          'Start Time': time,
          Duration: durationString,
          Platform: e.platform,
          'Is Complete?': _.capitalize(e.completed.toString()),
          ...new Array(NUM_AAT_TRIALS)
            .fill(0)
            .map((_, i) => i + 1)
            .map((trialId) => {
              // While it's a reasonably safe assumption that `trials` will contain exactly 80 elements, let's be defensive as this might change in future.
              const trial: TaskTrial | undefined =
                e.trials && e.trials[trialId - 1];

              return {
                id: trialId,
                imageOrientation:
                  e.trials && trial ? trial.imageOrientation : 'N/A',
                imageType: e.trials && trial ? trial.imageType : 'N/A',
                reactionTime:
                  e.trials && trial && trial.reactionTime !== null
                    ? trial.reactionTime!
                    : 'N/A',
                userResponse:
                  !e.trials || !trial || trial.userResponse === null
                    ? 'N/A'
                    : trial.userResponse === true
                    ? 'TRUE'
                    : 'FALSE',
              };
            })
            .reduce(
              (a, trial) => ({
                ...a,
                [`Trial ${trial.id} Image Orientation`]: trial.imageOrientation,
                [`Trial ${trial.id} Image Type`]: trial.imageType,
                [`Trial ${trial.id} Reaction Time`]: trial.reactionTime,
                [`Trial ${trial.id} User Response`]: trial.userResponse,
              }),
              {} as {
                [
                  trialHeader: string
                ]: // TODO: once we upgrade to newer typescript, let's type this properly (e.g. `Trial ${number} Reaction Time`)
                string | number;
              },
            ),
        };
      });

    let aatTaskSheet = oldWb?.Sheets['AAT Task'];
    if (aatTaskSheet == null) {
      aatTaskSheet = XLSX.utils.json_to_sheet(aatTaskJson);
      XLSX.utils.book_append_sheet(wb, aatTaskSheet, 'AAT Task');
    } else {
      let json = XLSX.utils.sheet_to_json(aatTaskSheet!);
      json = json.concat(aatTaskJson);

      XLSX.utils.sheet_add_json(aatTaskSheet!, json, {
        skipHeader: false,
        origin: 0,
      });
    }

    const aatReverseTaskJson = taskSessions
      .filter((e) => e.variant === 'AAT_REVERSE')
      .map((e) => {
        const date = moment(e.start_time).format('YYYY-MM-DD');
        const time = moment(e.start_time).format('HH:mm:ss');

        let start = moment(e.start_time);
        let end = moment(e.end_time);

        var duration = moment.duration(end.diff(start));
        let durationString = moment
          .duration(duration.asSeconds(), 'seconds')
          .format();

        return {
          'Participant ID': rowSource.participant_id,
          'App Version': e.app_version,
          'Start Date': date,
          'Start Time': time,
          Duration: durationString,
          Platform: e.platform,
          'Is Complete?': _.capitalize(e.completed.toString()),
          ...new Array(NUM_AAT_TRIALS)
            .fill(0)
            .map((_, i) => i + 1)
            .map((trialId) => {
              // While it's a reasonably safe assumption that `trials` will contain exactly 80 elements, let's be defensive as this might change in future.
              const trial: TaskTrial | undefined =
                e.trials && e.trials[trialId - 1];

              return {
                id: trialId,
                imageOrientation:
                  e.trials && trial ? trial.imageOrientation : 'N/A',
                imageType: e.trials && trial ? trial.imageType : 'N/A',
                reactionTime:
                  e.trials && trial && trial.reactionTime !== null
                    ? trial.reactionTime!
                    : 'N/A',
                userResponse:
                  !e.trials || !trial || trial.userResponse === null
                    ? 'N/A'
                    : trial.userResponse === true
                    ? 'TRUE'
                    : 'FALSE',
              };
            })
            .reduce(
              (a, trial) => ({
                ...a,
                [`Trial ${trial.id} Image Orientation`]: trial.imageOrientation,
                [`Trial ${trial.id} Image Type`]: trial.imageType,
                [`Trial ${trial.id} Reaction Time`]: trial.reactionTime,
                [`Trial ${trial.id} User Response`]: trial.userResponse,
              }),
              {} as {
                [
                  trialHeader: string
                ]: // TODO: once we upgrade to newer typescript, let's type this properly (e.g. `Trial ${number} Reaction Time`)
                string | number;
              },
            ),
        };
      });

    let aatReverseTaskSheet = oldWb?.Sheets['AAT Reverse Task'];
    if (aatReverseTaskSheet == null) {
      aatReverseTaskSheet = XLSX.utils.json_to_sheet(aatReverseTaskJson);
      XLSX.utils.book_append_sheet(wb, aatReverseTaskSheet, 'AAT Reverse Task');
    } else {
      let json = XLSX.utils.sheet_to_json(aatReverseTaskSheet!);
      json = json.concat(aatReverseTaskJson);

      XLSX.utils.sheet_add_json(aatReverseTaskSheet!, json, {
        skipHeader: false,
        origin: 0,
      });
    }

    const consumptionJson = [
      // prettier-ignore
      {
        'Participant ID': rowSource.participant_id,
        'Week 0 Consumption Days': rowSource.week0_consumption_days ? rowSource.week0_consumption_days?.join(',') : 'N/A',
        'Week 0 Consumption Drinks': rowSource.week0_consumption_drinks ? rowSource.week0_consumption_drinks.reverse().join(',') : 'N/A',
        'Week 0 Consumption Questions Completion Date': rowSource.week0_consumption_completion_date ? moment(rowSource.week0_consumption_completion_date)?.format('YYYY-MM-DD') : 'N/A',
        'Week 0 Consumption Questions Completion Time': rowSource.week0_consumption_completion_date ? moment(rowSource.week0_consumption_completion_date)?.format('HH:mm:ss') : 'N/A',
        'Waitlist Week 1 Consumption': rowSource.waitlist_week1_consumption_drinks ? rowSource.waitlist_week1_consumption_drinks.reverse().join(',') : 'N/A',
        'Waitlist Week 1 Consumption Questions Completion Date': rowSource.waitlist_week1_consumption_completion_date ? moment(rowSource.waitlist_week1_consumption_completion_date)?.format('YYYY-MM-DD') : 'N/A',
        'Waitlist Week 1 Consumption Questions Completion Time': rowSource.waitlist_week1_consumption_completion_date ? moment(rowSource.waitlist_week1_consumption_completion_date)?.format('HH:mm:ss') : 'N/A',
        'Waitlist Week 2 Consumption': rowSource.waitlist_week2_consumption_drinks ? rowSource.waitlist_week2_consumption_drinks.reverse().join(',') : 'N/A',
        'Waitlist Week 2 Consumption Questions Completion Date': rowSource.waitlist_week2_consumption_completion_date ? moment(rowSource.waitlist_week2_consumption_completion_date)?.format('YYYY-MM-DD') : 'N/A',
        'Waitlist Week 2 Consumption Questions Completion Time': rowSource.waitlist_week2_consumption_completion_date ? moment(rowSource.waitlist_week2_consumption_completion_date)?.format('HH:mm:ss') : 'N/A',
        'Waitlist Week 3 Consumption': rowSource.waitlist_week3_consumption_drinks ? rowSource.waitlist_week3_consumption_drinks.reverse().join(',') : 'N/A',
        'Waitlist Week 3 Consumption Questions Completion Date': rowSource.waitlist_week3_consumption_completion_date ? moment(rowSource.waitlist_week3_consumption_completion_date)?.format('YYYY-MM-DD') : 'N/A',
        'Waitlist Week 3 Consumption Questions Completion Time': rowSource.waitlist_week3_consumption_completion_date ? moment(rowSource.waitlist_week3_consumption_completion_date)?.format('HH:mm:ss') : 'N/A',
        'Waitlist Week 4 Consumption': rowSource.waitlist_week4_consumption_drinks ? rowSource.waitlist_week4_consumption_drinks.reverse().join(',') : 'N/A',
        'Waitlist Week 4 Consumption Questions Completion Date': rowSource.waitlist_week4_consumption_completion_date ? moment(rowSource.waitlist_week4_consumption_completion_date)?.format('YYYY-MM-DD') : 'N/A',
        'Waitlist Week 4 Consumption Questions Completion Time': rowSource.waitlist_week4_consumption_completion_date ? moment(rowSource.waitlist_week4_consumption_completion_date)?.format('HH:mm:ss') : 'N/A',
        'Week 1 Consumption': rowSource.week1_consumption_drinks ? rowSource.week1_consumption_drinks.reverse().join(',') : 'N/A',
        'Week 1 Consumption Questions Completion Date': rowSource.week1_consumption_completion_date ? moment(rowSource.week1_consumption_completion_date)?.format('YYYY-MM-DD') : 'N/A',
        'Week 1 Consumption Questions Completion Time': rowSource.week1_consumption_completion_date ? moment(rowSource.week1_consumption_completion_date)?.format('HH:mm:ss') : 'N/A',
        'Week 2 Consumption': rowSource.week2_consumption_drinks ? rowSource.week2_consumption_drinks.reverse().join(',') : 'N/A',
        'Week 2 Consumption Questions Completion Date': rowSource.week2_consumption_completion_date ? moment(rowSource.week2_consumption_completion_date)?.format('YYYY-MM-DD') : 'N/A',
        'Week 2 Consumption Questions Completion Time': rowSource.week2_consumption_completion_date ? moment(rowSource.week2_consumption_completion_date)?.format('HH:mm:ss') : 'N/A',
        'Week 3 Consumption': rowSource.week3_consumption_drinks ? rowSource.week3_consumption_drinks.reverse().join(',') : 'N/A',
        'Week 3 Consumption Questions Completion Date': rowSource.week3_consumption_completion_date ? moment(rowSource.week3_consumption_completion_date)?.format('YYYY-MM-DD') : 'N/A',
        'Week 3 Consumption Questions Completion Time': rowSource.week3_consumption_completion_date ? moment(rowSource.week3_consumption_completion_date)?.format('HH:mm:ss') : 'N/A',
        'Week 4 Consumption': rowSource.week4_consumption_drinks ? rowSource.week4_consumption_drinks.reverse().join(',') : 'N/A',
        'Week 4 Consumption Questions Completion Date': rowSource.week4_consumption_completion_date ? moment(rowSource.week4_consumption_completion_date)?.format('YYYY-MM-DD') : 'N/A',
        'Week 4 Consumption Questions Completion Time': rowSource.week4_consumption_completion_date ? moment(rowSource.week4_consumption_completion_date)?.format('HH:mm:ss') : 'N/A',
        'Week 8 Consumption Days': rowSource.week8_consumption_days ? rowSource.week8_consumption_days?.join(',') : 'N/A',
        'Week 8 Consumption Drinks': rowSource.week8_consumption_drinks ? rowSource.week8_consumption_drinks.reverse().join(',') : 'N/A',
        'Week 8 Consumption Questions Completion Date': rowSource.week8_consumption_completion_date ? moment(rowSource.week8_consumption_completion_date).format('YYYY-MM-DD') : 'N/A',
        'Week 8 Consumption Questions Completion Time': rowSource.week8_consumption_completion_date ? moment(rowSource.week8_consumption_completion_date).format('HH:mm:ss') : 'N/A',
        'Week 16 Consumption Days': rowSource.week16_consumption_days ? rowSource.week16_consumption_days?.join(',') : 'N/A',
        'Week 16 Consumption Drinks': rowSource.week16_consumption_drinks ? rowSource.week16_consumption_drinks.reverse().join(',') : 'N/A',
        'Week 16 Consumption Questions Completion Date': rowSource.week16_consumption_completion_date ? moment(rowSource.week16_consumption_completion_date).format('YYYY-MM-DD') : 'N/A',
        'Week 16 Consumption Questions Completion Time': rowSource.week16_consumption_completion_date ? moment(rowSource.week16_consumption_completion_date).format('HH:mm:ss') : 'N/A',
      },
    ];

    let consumptionSheet = oldWb?.Sheets['Participant Alcohol Consumption'];
    if (consumptionSheet == null) {
      consumptionSheet = XLSX.utils.json_to_sheet(consumptionJson);
      XLSX.utils.book_append_sheet(
        wb,
        consumptionSheet,
        'Participant Alcohol Consumption',
      );
    } else {
      let json = XLSX.utils.sheet_to_json(consumptionSheet!);
      json = json.concat(consumptionJson);

      XLSX.utils.sheet_add_json(consumptionSheet!, json, {
        skipHeader: false,
        origin: 0,
      });
    }

    const progressJson = [
      // prettier-ignore
      {
        'Participant ID': rowSource.participant_id,
        'App version': ((rowSource.task_sessions && rowSource.task_sessions[0]) || {app_version: 'N/A'}).app_version,
        'Registration Date': row.registrationDate,
        'Post-test Survey Expected Date': row.postTestSurveyDue,
        'No. personal alcohol images:': row.numPersonalAlcoholImages,
        'No. personal positive images:': row.numPersonalPositiveImages,
        // 'Week 8 Questions Expected Date': row.followUpQuestionsDue,
        // 'Week 8 Questions Complete?': row.followUpComplete,
        // 'Week 16 Questions Expected Date': row.followUpWeek16QuestionsDue,
        // 'Week 16 Questions Complete?': row.followUpWeek16Complete,
      },
    ];

    let progressSheet = oldWb?.Sheets['Participant Details'];
    if (progressSheet == null) {
      progressSheet = XLSX.utils.json_to_sheet(progressJson);
      XLSX.utils.book_append_sheet(wb, progressSheet, 'Participant Details');
    } else {
      let json = XLSX.utils.sheet_to_json(progressSheet!);
      json = json.concat(progressJson);

      XLSX.utils.sheet_add_json(progressSheet!, json, {
        skipHeader: false,
        origin: 0,
      });
    }

    if (oldWb == null) {
      XLSX.writeFile(wb, rowSource.participant_id + '.xlsx');
    }
  }

  render() {
    return (
      <StyledTableContainer>
        <DownloadContainer>
          <DownloadLabel>
            Documents
            <DownloadCountLabel>({this.state.data.length})</DownloadCountLabel>
          </DownloadLabel>
          <DownloadButton>
            <BlueButton
              variant="outlined"
              onClick={() => this.downloadMultiple()}>
              Download{' '}
              {this.state.selectedRowsNo === 0
                ? 'All'
                : this.state.selectedRowsNo}
            </BlueButton>
          </DownloadButton>
        </DownloadContainer>

        <StyledTable>
          <StyledHead>
            <TableRow>
              <TableCell padding="checkbox">
                <StyledCheckbox
                  checked={
                    this.state.data.length > 0 &&
                    this.state.selectedRowsNo === this.state.data.length
                  }
                  indeterminate={
                    this.state.selectedRowsNo > 0 &&
                    this.state.selectedRowsNo < this.state.data.length
                  }
                  onChange={() => this.onCheckAll()}
                />
              </TableCell>
              {HEADERS.map((header, columnIndex) => (
                <TableCell key={header}>
                  <Tooltip title="Sort" enterDelay={300} placement="right">
                    <TableSortLabel
                      active={
                        this.state.columnSortDirection[columnIndex] !==
                        undefined
                      }
                      direction={this.state.columnSortDirection[columnIndex]}
                      onClick={() => {
                        const newColumnDirection =
                          this.state.columnSortDirection[columnIndex] ===
                          undefined
                            ? 'asc'
                            : this.state.columnSortDirection[columnIndex] ===
                              'asc'
                            ? 'desc'
                            : 'asc';
                        const columnAscendingSorted = [...this.state.data].sort(
                          (a, b) =>
                            getSortValueForColumnIndex(a, columnIndex) <
                            getSortValueForColumnIndex(b, columnIndex)
                              ? -1
                              : getSortValueForColumnIndex(a, columnIndex) >
                                getSortValueForColumnIndex(b, columnIndex)
                              ? 1
                              : a.sourceDataIndex < b.sourceDataIndex
                              ? -1
                              : a.sourceDataIndex > b.sourceDataIndex
                              ? 1
                              : 0,
                        );
                        this.setState({
                          columnSortDirection: {
                            [columnIndex]: newColumnDirection,
                          },
                          data:
                            newColumnDirection === 'asc'
                              ? columnAscendingSorted
                              : columnAscendingSorted.reverse(),
                        });
                      }}>
                      <p>{header}</p>
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
              ))}
              <TableCell align="right">
                <ActionLabel>ACTION</ActionLabel>
              </TableCell>
            </TableRow>
          </StyledHead>
          {this.state.data.length !== 0 && (
            <TableBody>
              {this.state.data.map((row, index) => (
                <DataRow
                  key={row.sourceDataIndex}
                  index={index}
                  row={row}
                  onCheck={this.onCheck.bind(this)}
                  download={this.download.bind(this)}
                />
              ))}
            </TableBody>
          )}
        </StyledTable>
        {this.state.loading && <StyledProgress />}
      </StyledTableContainer>
    );
  }
}

type DataRowProps = {
  index: number;
  row: ParticipantRowDisplay;
  onCheck: (index: number, checked: boolean) => void;
  download: (row: ParticipantRowDisplay) => void;
};

// Using memoisation to optimise performance, as material-ui's table component is still working on improving efficiency.
// See: https://github.com/material-table-core/core/issues/224#issuecomment-860349184
const DataRow = React.memo(
  (props: DataRowProps) => (
    <StyledRow>
      <TableCell padding="checkbox">
        <StyledCheckbox
          checked={props.row.isSelected}
          onChange={(_, checked) => props.onCheck(props.index, checked)}
        />
      </TableCell>
      <DataRowStatic {...props} />
    </StyledRow>
  ),
  (prevProps, nextProps) => {
    if (prevProps.index !== nextProps.index) {
      return false;
    }
    if (prevProps.row.isSelected !== nextProps.row.isSelected) {
      return false;
    }
    return true;
  },
);

const DataRowStatic = React.memo(
  (props: DataRowProps) => (
    <>
      <TableCell>{props.row.participantId}</TableCell>
      <TableCell>{props.row.registrationDate}</TableCell>
      <TableCell>{props.row.postTestSurveyDue}</TableCell>
      {/*<TableCell>{props.row.followUpQuestionsDue}</TableCell>
      <TableCell>{props.row.followUpComplete}</TableCell>
      <TableCell>{props.row.followUpWeek16QuestionsDue}</TableCell>
      <TableCell>{props.row.followUpWeek16Complete}</TableCell>*/}
      <TableCell align="right" style={{width: '120px'}}>
        <GrayButton
          variant="outlined"
          onClick={() => props.download(props.row)}>
          Download
        </GrayButton>
      </TableCell>
    </>
  ),
  (prevProps, nextProps) => {
    if (prevProps.index !== nextProps.index) {
      return false;
    }
    return true;
  },
);

const StyledCheckbox = withStyles(() => ({
  root: {
    '&$checked': {
      color: '#00B0FF',
    },
    color: '#B9B9B9',
  },
  checked: {},
}))(Checkbox);

const TableCell = withStyles(() => ({
  root: {
    fontSize: '14px',
    padding: '5px',
    borderBottom: 'none',
  },
  body: {
    borderBottom: '0px',
  },
}))(MUITableCell);

const BlueButton = withStyles({
  root: {
    minWidth: '120px',
    height: '30px',
    marginRight: '10px',
    color: '#00B0FF',
    borderRadius: 25,
    borderColor: '#00B0FF',
  },
  label: {
    fontSize: '12px',
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
})(Button);

const GrayButton = withStyles({
  root: {
    width: '100px',
    height: '30px',
    marginRight: '10px',
    color: '#5D5D5D',
    borderRadius: 25,
    borderColor: '#5D5D5D',
  },
  label: {
    fontSize: '12px',
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
})(Button);

const StyledTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f7f9fc;
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 30px;
  margin-right: 30px;
`;

const DownloadContainer = styled.div`
  width: 100vw;
  height: 50px;
  margin-top: 50px;
`;

const DownloadLabel = styled.div`
  font-size: 25px;
  color: #535353;
  margin-left: 30px;
`;

const DownloadCountLabel = styled.span`
  font-size: 17px;
  color: #00b0ff;
  margin-left: 10px;
  margin-bottom: 2px;
`;

const DownloadButton = styled.div`
  position: absolute;
  top: 100px;
  right: 37px;
`;

const StyledTable = styled(Table)`
  border-spacing: 0 10px !important;
  border-collapse: separate !important;
`;

const StyledHead = styled(TableHead)`
  width: 100%;
  background-color: #f7f9fc;
`;

const StyledRow = styled(TableRow)`
  width: 100%;
  background-color: white;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 10px;
`;

const ActionLabel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledProgress = withStyles({
  root: {
    marginBottom: '15px',
  },
})(CircularProgress);

export default DataTable;
